<template>
    <div v-if="ready && selectedFiltered_client_details">
        <!-- <pre>{{ selectedFiltered_client_details }}</pre> -->
        <!-- start -->

        <div v-if="core.marketing_banner_src.length > 0" class="mb-4 d-flex justify-content-center">
            <b-img :src="core.marketing_banner_src" fluid alt="Responsive image"></b-img>
        </div>
        <b-card style="" v-if="loggedIn_user_role == 1">
            <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-primary mb-2 mr-2 float-left" @click="resetClientData">
                    <i class="mdi mdi-arrow-left mr-1"></i> Go back
                </button>
            </div>
        </b-card>
        <!-- end -->
        <!-- start -->
        <b-card style="">
            <div class="d-flex justify-content-between">
                <h3>
                    {{ selectedFiltered_client_details.member_name }}
                </h3>

                <button type="button" class="btn btn-primary mb-2 mr-2 float-left"
                    @click="showEditClientModal">
                    <i class="mdi mdi-pencil-box-outline mr-1"></i> Edit Details
                </button>
            </div>

            <div class="table-responsive mt-3">
                <table class="table table-sm m-0 bordered">
                    <thead>
                        <tr>
                            <th>Member No.</th>
                            <th>Contact No.</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {{ selectedFiltered_client_details.member_number }}
                            </td>
                            <td>
                                {{ selectedFiltered_client_details.contact_no }}
                            </td>
                            <!-- <td>
                                {{ selectedFiltered_client_details.member_name }}
                            </td> -->
                            <td>
                        <tr>
                            {{
                                selectedFiltered_client_details.emails
                            }}
                        </tr>
                        </td>
                        </tr>
                    </tbody>

                    <thead>
                        <tr>
                            <th>City</th>
                            <th>Province</th>
                            <th>Postal Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {{ selectedFiltered_client_details.city }}
                            </td>
                            <td>
                                {{ selectedFiltered_client_details.province }}
                            </td>
                            <td>
                                {{ selectedFiltered_client_details.postal_code }}
                            </td>
                        </tr>
                    </tbody>

                    <thead>
                        <tr>
                            <th>Home Branch</th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ selectedFiltered_client_details.branch_name }}</td>
                            <td>
                                {{ selectedFiltered_client_details.address }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex">
                <button  type="button" class="btn btn-primary mb-2 mr-2 float-left"
                    @click="generateMemberCard">
                    <i class="mdi mdi-download mr-1"></i> Download Member Card
                </button>
                <button v-show="loggedIn_user_role == 1 && !this.isProfileComplete" type="button" class="btn btn-success mb-2 mr-2 float-left"
                    @click="activateMembership">
                    <i class="mdi mdi-plus mr-1"></i> Activate Membership
                </button>
            </div>
        </b-card>
        <!-- end -->

        <!-- start for tabs -->

        <b-card>
            <div class="d-flex justify-content-between"></div>
            <b-tabs>
                <b-tab @click="checkTab('orders')" title="Orders" active class="primary" :disabled="!isProfileComplete">
                    <simpleLayout :items_count="10">
                        <template #content>
                            <div class="row">
                                <div class="col-sm-12">
                                    <button type="button" class="btn btn-success mb-2 mr-2 float-left" @click="addOrder">
                                        <i class="mdi mdi-plus mr-1"></i> Create new order
                                    </button>
                                </div>
                                <div class="col-sm-12">
                                    <clientOrderTable :repUser="repUser" />
                                </div>
                            </div>
                        </template>
                    </simpleLayout>
                </b-tab>
                <b-tab @click="checkTab('users')" title="Users" class="primary" :disabled="!isProfileComplete" v-if="loggedIn_user_role == 1">
                    <simpleLayout :items_count="10">
                        <template #content>
                            <div class="row">
                                <div class="col-sm-12">
                                    <button type="button" class="btn btn-success mb-2 mr-2" @click="showAddUserClientModal">
                                        <i class="mdi mdi-plus mr-1"></i> Add a user
                                    </button>
                                </div>
                                <div class="col-sm-12">
                                    <clientUsersTable />
                                </div>
                            </div>
                        </template>
                    </simpleLayout>
                </b-tab>
                <b-tab @click="checkTab('profile')" title="Member CannaProfile" class="primary" v-if="core.site_config.show_member_questions">
                    <simpleLayout :items_count="10">
                        <template #content>
                            <div class="row">
                                <div class="col-sm-12">
                                    <memberQuestionsForm @refresh="loadClientDetails"/>
                                </div>
                            </div>
                        </template>
                    </simpleLayout>
                </b-tab>
            </b-tabs>
        </b-card>

        <!-- end  -->

        <clientUsersAddModal v-if="show_modal" />
        <clientEditModal v-if="show_modal_editClient" />
        <membershipCheckout v-if="showMembershipModal"></membershipCheckout>
        <uploadClientIdModal v-if="showUploadIdModal" :client_id="clientId"></uploadClientIdModal>
        <div v-show="false">
            <memberCard :memberData="selectedFiltered_client_details"></memberCard>
        </div>
        
        

    </div>
</template>

<script>
import simpleLayout from "@/components/layout-templates/simple-layout.vue";
import clientOrderTable from "./clientOrderTable.vue";
import clientUsersTable from "./clientUsersTable.vue";
import clientUsersAddModal from "./clientUsersAddModal.vue";
import clientEditModal from "./clientEditModal.vue";
//import memberQuestionsForm from './memberQuestionsForm.vue';
import memberCard from "./memberCard.vue";
import membershipCheckout from "./membershipCheckout.vue";
import uploadClientIdModal from "./uploadClientIdModal.vue";
import { adminComputed, clientMethods, clientComputed, repMethods, repComputed } from "@/state/helpers";
import Swal from "sweetalert2";
export default {
    components: {
        //eslint-disable-next-line
        simpleLayout,
        //eslint-disable-next-line
        clientOrderTable,
        //eslint-disable-next-line
        clientUsersTable,
        clientUsersAddModal,
        clientEditModal,
        //memberQuestionsForm,
        memberCard,
        membershipCheckout,
        uploadClientIdModal
    },
    data: () => ({
        clientId: -1,
        show_modal: false,
        show_modal_editClient: false,
        show_modal_addOrder: false,
        showMembershipModal: false,
        ready: true,
        busySaving: false,
        viewable: false,
        exportCard: false,
        showUploadIdModal: false,
    }),
    props: {
        repUser: Boolean,
    },
    computed: {
        ...adminComputed,
        ...clientComputed,
        ...repComputed,
        getUserRole() {
            return this.loggedIn_user_role;
        },
        client_type_select_options() {
            return [
                {
                    value: null,
                    text: "Please select an option",
                    disabled: true,
                },
                { value: "stockist", text: "Stockist" },
                { value: "reseller", text: "Reseller" },
                { value: "individual", text: "Individual" },
            ];
        },
        clientDetails() {
            return this.selected_client_details;
        },
        questions() {
            return this.clientDetails.member_questions;
        },
        isProfileComplete() {
            return this.clientDetails.valid_membership;
        },
        isIdUploaded() {
            return this.clientDetails.id_uploaded;
        },
    },
    methods: {
        ...clientMethods,
        ...repMethods,
        resetClientData() {
            this.setSelectedClientId(-1);
            // this.$router.push({ path: "/clients" });
            //go back to prev route
            this.$router.back();
        },
        checkTab() {
            if (this.client_users.length > 0) {
                this.$nextTick(() => {
                    window.webix.$$("clientUsersTable").refresh();
                });
            }

            //shows the + Add a user button
            //data == "orders" ? (this.viewable = false) : (this.viewable = true);
        },
        activateMembership() {
            Swal.fire({
            title: 'Activate Membership',
            text: 'Please select a membership type:',
            input: 'select',
            inputOptions: this.clientDetails.membership_types.reduce((options, type) => {
                options[type.id] = type.name;
                return options;
            }, {}),
            inputPlaceholder: 'Select a membership type',
            showCancelButton: true,
            confirmButtonText: 'Activate',
            cancelButtonText: 'Cancel',
            }).then((result) => {
            if (result.isConfirmed) {
                const selectedMembership = this.clientDetails.membership_types.find(type => type.id == result.value);
                let params = {
                    client_id: this.clientDetails.id,
                    membership_id: selectedMembership.id
                };
                this.activateClient(params).then(() => {
                    Swal.fire('Activated!', `Membership type: ${selectedMembership.name}`, 'success');
                    this.loadClientDetails();
                });
            }
            });
        },
        showAddUserClientModal() {
            this.show_modal = true;
            this.$nextTick(() => {
                this.$bvModal.show("clientUsersAddModal");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "clientUsersAddModal") {
                        this.show_modal = false;
                        this.edit_id = -1;
                    }
                });
            });
        },
        showEditClientModal() {
            this.show_modal_editClient = true;
            this.$nextTick(() => {
                this.$bvModal.show("clientEditModal");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "clientEditModal") {
                        this.show_modal_editClient = false;
                        this.edit_id = -1;
                    }
                });
            });
        },
        generateMemberCard() {
            this.$eventHub.$emit('renderCard');
        },
        addOrder() {
            this.show_modal_addOrder = true;
            let params;
            if (this.repUser) {
                params = {
                    client_id: this.$route.params.clientid,
                    rep_id: this.selected_rep_id,
                };
            } else {
                params = {
                    client_id: this.$route.params.clientid,
                };
            }
            this.setSelectedClientDetails(params);

            this.$router.push({ path: `/create-order/${this.$route.params.clientid}` });

            // this.show_modal_addOrder = false;
            // this.$nextTick(() => {
            //     this.$bvModal.show("clientCreateOrderModal");
            //     this.$root.$once("bv::modal::hidden", (event) => {
            //         if (event.type == "hidden" && event.componentId == "clientCreateOrderModal") {
            //             //will refresh the table on screen
            //             let params;
            //             if (this.repUser) {
            //                 params = {
            //                     client_id: this.$route.params.clientid,
            //                     rep_id: this.selected_rep_id,
            //                 };
            //             } else {
            //                 params = {
            //                     client_id: this.$route.params.clientid,
            //                 };
            //             }
            //             this.setSelectedClientDetails(params);
            //             this.show_modal_addOrder = false;
            //         }
            //     });
            // });
        },
        checkIfProfileComplete() {
            if (this.user_role == "admin") {
                return;
            }
            if (!this.isProfileComplete) {
                this.showMembershipModal = true;
                this.$nextTick(() => {
                    this.$bvModal.show("activateModal");
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "activateModal") {
                            this.showMembershipModal = false;
                        }
                    });
                });
            } else if (!this.isIdUploaded) {
                this.showUploadIdModal = true;
                this.$nextTick(() => {
                    this.$bvModal.show("uploadIdModal");
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "uploadIdModal") {
                            this.showUploadIdModal = false;
                        }
                    });
                });
            }
        },
        loadClientDetails() {
            let params;
            if (this.repUser) {
                params = {
                    client_id: this.$route.params.clientid,
                    rep_id: this.selected_rep_id,
                };
            } else {
                params = {
                    client_id: this.$route.params.clientid,
                };
            }
            this.setSelectedClientId(this.$route.params.clientid).then(() => {
                this.setSelectedClientDetails(params).then(() => {
                    this.ready = true;
                    this.setFilteredSelectedClientDetails();
                    this.$nextTick(() => {
                        this.checkIfProfileComplete();
                    });
                });
            });
        },
    },
    mounted() {
        // alert(`aler ${this.$route.params.clientid}`);

        if (this.clients.length == 0) {
            this.getClients().then(() => {
                //method unique to this component
                this.loadClientDetails();
                // this.ready = true;
            });
        } else {
            this.loadClientDetails();
        }

        if (this.repUser && this.selected_rep_id == -1) {
            this.$router.push("/");
        }

        this.clientId = this.$route.params.clientid;
    },
};
</script>

<style lang="scss" scoped></style>
