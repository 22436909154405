<template>
    <div>
        <b-modal size="lg" title="Upload ID" hide-footer no-close-on-backdrop no-close-on-esc hide-header-close id="uploadIdModal">
            <div class="modal-body" style="width: 100%">
                <b-alert show variant="primary"> Please upload a picture of your ID / Drivers license to continue</b-alert>
                <b-card-text>
                    <uploader
                        :options="renderUploadOptions"
                        :autoStart="false"
                        :ref="'id_upload'"
                        class="uploader-example mt-3"
                        @file-added="onFileAdded"
                    >
                    <uploader-unsupport></uploader-unsupport>
                    <uploader-drop>
                        <p>Drop file here to upload or</p>
                        <uploader-btn type="button" :single="false">Select file</uploader-btn>
                    </uploader-drop>
                    <uploader-list></uploader-list>
                    </uploader>
                </b-card-text>
                <div class="mt-3 d-flex justify-content-end">
                    <b-button v-show="idAdded" variant="primary" class="ml-2" @click="uploadMemberId()">Upload</b-button>
                </div>
            </div>
        </b-modal>

    </div>
</template>
<script>
export default {
    data: () => ({
        idAdded: false,
    }),
    props: {
        client_id: {
            type: String,
            required: true,
        },
    },
    computed: {
        renderUploadOptions() {
            let url = process.env.VUE_APP_API_BASEURL + "api/crm/upload_id_dash";
            let options = {
                target: url,
                query: {
                    client_id: this.client_id,
                },
                testChunks: false,
                simultaneousUploads: 1, 
                fileParameterName: 'image', //Set the upload post param which will contain the binary payload
                singleFile: true, //Limit upload to one file
            };
            return options;
        },
    },
    methods: {
        onFileAdded() {
            this.idAdded = true;
        },
        uploadMemberId() {
            let el = this;
            let AUTH_TOKEN = window.localStorage.getItem("x_token");
            const uploaderInstance = this.$refs['id_upload'];
            const upload = uploaderInstance.uploader;
            upload.opts.headers.Authorization = "Bearer " + AUTH_TOKEN;
            upload.resume();
            upload.on('fileSuccess', function() { 
                el.$bvModal.hide("uploadIdModal");
            });
        },
    },
    mounted() {

    },
};
</script>