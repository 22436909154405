<template>
    <b-overlay :show="busySaving">
        <b-form class="mw-500 mb-4" @submit.prevent="saveClientForm">
                            <!-- Your form groups and inputs here -->

            <b-form-group id="example-input-group-1" label="Member Full Name" label-for="example-input-1">
                <b-form-input
                    v-model="$v.form.first_name.$model"
                    :state="validateState('first_name')"
                    id="example-input-1"
                    name="first-name"
                    aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                    >This is a required field and must be at least 2 characters.</b-form-invalid-feedback
                >
            </b-form-group>

            <b-form-group id="example-input-group-1" label="Contact No" label-for="example-input-1">
                <b-form-input
                    id="contact-no"
                    name="contact-no"
                    aria-describedby="input-1-live-feedback"
                    v-model="$v.form.contact_no.$model"
                    :state="validateState('contact_no')"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback">Contact Numbers are 10 digits</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="example-input-group-1" label="ID No" label-for="example-input-1">
                <b-form-input
                    name="id-no"
                    aria-describedby="input-1-live-feedback"
                    v-model="$v.form.id_no.$model"
                    :state="validateState('id_no')"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback">ID Numbers are 13 digits</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Date of Birth">

            <b-form-datepicker
                ref="datePicker"
                v-model="$v.form.dob.$model"
                :state="validateState('dob')"
                placeholder="YYYY-MM-DD"
                show-decade-nav
            ></b-form-datepicker>
            </b-form-group>

            <b-form-group label="Address">
            <b-form-input 
                v-model="$v.form.street.$model"
                :state="validateState('street')"
                placeholder="123 Main St"
            ></b-form-input>
            </b-form-group>

            <b-form-group label="City">
            <b-form-input 
                v-model="$v.form.city.$model"
                :state="validateState('city')"
                placeholder="City"
            ></b-form-input>
            </b-form-group>

            <b-form-group label="Postal Code">
            <b-form-input 
                v-model="$v.form.postal_code.$model"
                :state="validateState('postal_code')"
                placeholder="Postal Code"
            ></b-form-input>
            </b-form-group>

            <b-form-group label="Province">
                <b-form-select
                    v-model="$v.form.province.$model"
                    :state="validateState('province')"
                    :options="client_province_select_options"
                    id="province"
                    name="province"
                    aria-describedby="client-type-live-feedback"
                ></b-form-select>
                <b-form-invalid-feedback id="client-type-live-feedback">This is a required field.</b-form-invalid-feedback>
            </b-form-group>

            
            <b-form-group id="example-input-group-1" label="Email" label-for="example-input-1">
                <b-form-input
                    v-model="$v.form.email.$model"
                    :state="validateState('email')"
                    type="email"
                    id="email"
                    name="email"
                    aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback">Needs to be a valid email format</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Branch">
                <b-form-select
                    v-model="$v.form.branch_id.$model"
                    :state="validateState('province')"
                    :options="branchesForSelect"
                    id="branch"
                    name="branch"
                    aria-describedby="client-type-live-feedback"
                ></b-form-select>
                <b-form-invalid-feedback id="client-type-live-feedback">This is a required field.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-checkbox
                id="checkbox-1"
                v-model="form.newsletter"
                name="checkbox-1"
                value="true"
                unchecked-value="false"
                size="lg"
                class="float-left"
            >
                Newsletter
            </b-form-checkbox>
            <div class="modal-footer">
                <div class="d-flex justify-content-between mt-2"></div>
                <b-button v-if="!edit" type="submit" variant="primary">Submit</b-button>
                <b-button v-if="edit" type="submit" variant="primary">update</b-button>

                <button type="button" class="btn btn-danger" @click="closeModal()">Close</button>
            </div>
        </b-form>

    </b-overlay>
</template>

<script>
//eslint-disable-next-line
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
//eslint-disable-next-line
import { required, minLength, maxLength, email } from "vuelidate/lib/validators";
import { clientMethods, clientComputed, crmMethods, adminComputed } from "@/state/helpers";

export default {
    mixins: [validationMixin],
    props: {
        edit: {
            Boolean,
            required: false,
        },
        clientId: { Number },
    },
    data: () => ({
        formSubmit: false,
        busySaving: false,
        form: {
            id: -1,
            branch_id: null,
            first_name: "",
            last_name: "",
            contact_no: "",
            id_no: "",
            email: "",
            province: null,
            dob: "",
            street: "",
            city: "",
            postal_code: "",
            type: null,
            newsletter: "false",
        },
    }),
    validations: {
        form: {
            first_name: {
                required,
                minLength: minLength(2),
            },
            contact_no: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10),
            },
            id_no: {
                required,
                minLength: minLength(13),
                maxLength: maxLength(13),
            },
            email: {
                required,
                email,
            },
            province: {
                required,
            },
            branch_id: {
                required,
            },
            dob: {
                required,
            },
            street: {
                required,
            },
            city: {
                required,
            },
            postal_code: {
                required,
            },
        },
    },
    computed: {
        ...clientComputed,
        ...adminComputed,
        branchesForSelect() {
            return [
                {
                    value: null,
                    text: "Please select an option",
                    disabled: true,
                },
                ...this.core.branch_config.map(branch => ({
                    value: branch.id,
                    text: branch.name.trim(),
                })),
            ];
        },
        client_type_select_options() {
            // need to confirm the details here with api config

            return [
                {
                    value: null,
                    text: "Please select an option",
                    disabled: true,
                },
                { value: "stockist", text: "Stockist" },
                { value: "individual", text: "Individual" },
            ];
        },
        client_province_select_options() {
            // need to confirm the details here with api config

            return [
                {
                    value: null,
                    text: "Please select an option",
                    disabled: true,
                },
                { value: "Western Cape", text: "Western Cape" },
                { value: "Eastern Cape", text: "Eastern Cape" },
                { value: "Free State", text: "Free State" },
                { value: "Gauteng", text: "Gauteng" },
                { value: "KwaZulu-Natal", text: "KwaZulu-Natal" },
                { value: "Limpopo", text: "Limpopo" },
                { value: "Mpumalanga", text: "Mpumalanga" },
                { value: "North-West", text: "North-West" },
                { value: "Northern-Cape", text: "Northern-Cape" },
            ];
        },
    },
    methods: {
        ...clientMethods,
        ...crmMethods,
        validateState(name) {
            if (this.formSubmit) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            }
        },
        closeModal() {
            this.$bvModal.hide("clientModal");
            this.$bvModal.hide("clientEditModal");
        },
        saveClientForm() {
            this.formSubmit = true;

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            this.busySaving = true;
            if (this.edit) {
                //this will run when the clientEditModal is clicked
                // this.form.id = this.clientId;
                //eslint-disable-next-line
                Swal.fire({
                    title: `Are you sure you would like to update the details of: ${this.form.first_name}"?`,
                    // text: "You won't be able to undo this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, upate!",
                }).then((result) => {
                    if (result.value) {
                        this.saveNewMember(this.form).then(() => {
                            this.getClients().then(() => {
                                this.busySaving = false;
                                this.$bvModal.hide("clientEditModal");
                                Swal.fire("Member updated!", `${this.form.first_name} was updated.`, "success");
                                //NB: this will refresh the table that stores the clients information
                                this.setSelectedClientDetails({ client_id: this.$route.params.clientid }).then(() => {
                                    this.setFilteredSelectedClientDetails();
                                });
                            });
                        });
                    }
                });
            } else {
                this.saveNewMember(this.form).then(() => {
                    this.getClients().then(() => {
                        this.busySaving = false;
                        this.$bvModal.hide("clientModal");
                        this.$bvModal.hide("clientModal");
                        Swal.fire("Completed", `Client was added`, "success");
                    });
                });
            }
        },
    },
    mounted() {
        //edit is passed as prop as this page generates two different versions of the same form
        if (this.edit) {
            //id is passed as a prop, otherwise it is set to -1
            this.form = {
                id: this.clientId,
                branch_id: this.selected_client_details.branch_id,
                first_name: this.selected_client_details.member_name,
                //last_name: this.selected_client_details.last_name,
                contact_no: this.selected_client_details.contact_no,
                id_no: this.selected_client_details.id_number,
                email: this.selected_client_details.emails,
                province: this.selected_client_details.province,
                dob: this.selected_client_details.date_of_birth,
                street: this.selected_client_details.address,
                city: this.selected_client_details.city,
                postal_code: this.selected_client_details.postal_code,
                type: this.selected_client_details.type,
                newsletter: this.selected_client_details.newsletter,
            };
        }
    },
};
</script>
