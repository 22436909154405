<template>
    <div>
        <b-modal size="lg" title="Activate Membership" hide-footer no-close-on-backdrop no-close-on-esc hide-header-close id="activateModal">
            <div class="modal-body" style="width: 100%">
                <b-alert show variant="primary"> Please activate your membership to continue</b-alert>
                <b-card-text>
                    <div class="card">
                        <div class="card-body">
                            <div class="card shadow-none border mb-0">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Membership Summary</h4>

                                    <div class="table-responsive">
                                        <table class="table table-centered mb-0 table-nowrap">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th scope="col">Membership</th>
                                                    <th scope="col">Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h5 class="font-size-14 text-truncate">
                                                            Standard Membership
                                                        </h5>
                                                        <p class="text-muted mb-0">6 Months</p>
                                                    </td>
                                                    <td>R200</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-sm-6">
                            <a class="btn btn-success" @click="proceedToPayment()">
                                Proceed to payment
                            </a>
                        </div>
                    </div>
                </b-card-text>
            </div>
        </b-modal>

    </div>
</template>
<script>
import {
    orderMethods,
    orderComputed,
} from "@/state/helpers";
export default {
    data() {
        return {
            
        };
    },
    computed: {
        ...orderComputed,
    },
    methods: {
        ...orderMethods,
        proceedToPayment() {
            this.createMembershipOrder({membership_id: 1}).then((response) => {
                if (response.success) {
                    window.location.href = response.payment_url;
                }
            });
        }
    },
    mounted() {

    },
};
</script>